<template>
  <div class="card">
    <div class="card-header"><h5>Assigned Classes</h5></div>
    <div class="card-body">
      <div class="table-responsive">
        <b-table
        ref="marker_table"
        show-empty
        selectable
        :items="tableRecords"
        :fields="tableFields"
        @row-selected="rowSelected"
        selected-variant="success"
        >
          <template #cell(details)="row">
            <b-row>
              <b-col cols="12">{{row.item.name}}</b-col>
              <b-col cols="12"><strong>Institute: </strong>{{ row.item.institute.name }}</b-col>
              <b-col cols="12"><strong>Teacher %: </strong>{{row.item.teacher_percentage}}</b-col>
              <b-col cols="12"><strong>Fee: </strong>Rs. {{row.item.class_fee}}</b-col>
            </b-row>
          </template>

          <template #cell(discount_type)="row" class="text-right">
            <div class="row">
              <div class="col-12">
                <label class="d-block">
                  <input class="radio_animated" @change="updateCart" :name="row.item.id" type="radio" v-model="row.item.discount_type" :checked="row.item.discount_type===1" value="1" > Amount
                </label>
              </div>
              <div class="col-12">
                <label class="d-block">
                  <input class="radio_animated" @change="updateCart" :name="row.item.id" type="radio" v-model="row.item.discount_type" :checked="row.item.discount_type===0" value="0" > Rate
                </label>
              </div>
              <div class="col-12">
                <label class="d-block">
                  <input class="checkbox_animated" @change="updateCart" :name="row.item.id" type="checkbox" v-model="row.item.half_card" :checked="row.item.half_card===1" value="1" > Half Card
                </label>
              </div>
            </div>
          </template>
          <template #cell(discount_value)="row" class="text-right">
            <b-form-input @keyup="updateCart" class="text-right" :min="0" :max="(row.item.discount_type===1 ? null : 99)" v-model="row.item.discount_value"></b-form-input>
          </template>
          <template #cell(payable)="row" class="text-right">
            <span class="pull-right">{{row.item.payable || row.item.class_fee}}</span>
          </template>

          <template #cell(action)="row" class="text-right">
            <div style="width: 99%">
              <b-row>
                <b-col cols="12" class="mb-1">
                  <b-button class="btn-square btn-block" size="sm" variant="primary" @click="showFreeCardModal(row.index, row.item, $event.target)" title="Add Free Card">Add Free Card</b-button>
                </b-col>
                <b-col cols="12" class="mb-1">
                  <b-button class="btn-square btn-block" size="sm" variant="primary" @click="showBarrierModal(row.index, row.item, $event.target)" title="Add Exemption">Add Exemption</b-button>
                </b-col>
                <b-col cols="12" class="mb-1">
                  <b-button class="btn-square btn-block" size="sm" variant="danger" @click="showDeleteModal(row.index, row.item, $event.target)" title="Remove class from student">Remove Class</b-button>
                </b-col>
              </b-row>
            </div>
          </template>

        </b-table>
      </div>
      <div>
        <div class="form-group">
          <label for="note">Note (optional)</label>
          <b-textarea v-model="note" id="note" class="form-control"></b-textarea>
        </div>
      </div>
      <div class="mt-20">
        <b-row>
          <b-col cols="12" md="3" class="text-right">
            <table>
              <tr>
                <td>Sub Total:</td>
                <td><strong>{{cartSubTotal}}</strong></td>
              </tr>
              <tr>
                <td>Total Discounts:</td>
                <td class="text-left"><strong>{{parseFloat(cartDiscTotal)}}</strong></td>
              </tr>
              <tr>
                <td>Total:</td>
                <td><strong>{{cartTotal}}</strong></td>
              </tr>
            </table>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Payment Month">
              <month-picker-input
                  @change="setPaymentMonth"
                  v-model="payment_month"
                  :default-year="default_year"
                  :default-month="default_month"
                  :input-pre-filled="true"
                  alignment="top"
                  selectedBackgroundColor="green"
                  style="z-index: 5;"
              ></month-picker-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Payment Method">
              <b-form-select @change="updateCart" class="btn-square border" v-model="payment_method" :options="paymentMethods" value-field="id" text-field="text" required>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3" >
            <label for="printNowCheckbox" class="flex-centered"><input id="printNowCheckbox" type="checkbox" v-model="printNow" @click="togglePrint"> Print Invoice</label>
            <b-button :disabled="processingPayment || selected.length < 1" @click.prevent="submitInvoice" class="btn-square btn-block" variant="primary">Pay&nbsp;Now</b-button>
          </b-col>
        </b-row>

      </div>
    </div>




    <!-- Begin Delete Modal -->
    <b-modal id="delete_modal" title="Delete" cancel-variant="default" ok-variant="danger" ok-title="Confirm" @hide="clearDeleteModals" @ok="submitDeleteModal">
      <h6 class="m-b-10">Please confirm removal of following class from selected student:</h6>
      <div class="row">
        <div class="col-md-2"><strong>Class</strong></div>
        <div class="col-md-10"><div class="font-danger" v-text="deleteModal.class_name"></div></div>
      </div>
    </b-modal>
    <!-- End Delete Modal -->



    <!-- Begin Free Card Modal -->
    <b-modal id="free_card_modal" title="Add Free Card" cancel-variant="default" ok-variant="primary" ok-title="Confirm" @hide="clearFreeCardModal" @ok="submitFreeCardModal">
      <div class="form-row">
        <div class="col-lg-6">
          <b-form-group label="Start Date *">
            <month-picker-input
                v-model="freeCardForm.start_at"
                :default-year="default_year"
                :default-month="default_month"
                :input-pre-filled="true"
                alignment="top"
                style="z-index: 5;width: 100%;"
            ></month-picker-input>
          </b-form-group>
        </div>
        <div class="col-lg-6">
          <b-form-group label="End Date *">
            <month-picker-input
                v-model="freeCardForm.end_at"
                :default-year="default_year"
                :default-month="default_month"
                :input-pre-filled="true"
                alignment="top"
                style="z-index: 5;width: 100%;"
            ></month-picker-input>
          </b-form-group>
        </div>
      </div>

      <b-form-group label="Remark *">
        <b-form-textarea v-model.lazy="freeCardForm.remark"></b-form-textarea>
      </b-form-group>

    </b-modal>
    <!-- End Free Card Modal -->

    <!-- Begin Barrier Modal -->
    <b-modal id="barrier_modal" title="Add Exemption" cancel-variant="default" ok-variant="primary" ok-title="Confirm" @hide="clearBarrierModal" @ok="submitBarrierModal">

      <div class="form-row">
        <div class="col-lg-6">
          <b-form-group label="Start Date *">
            <b-form-datepicker v-model="barrierForm.start_at" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" reset-button today-button></b-form-datepicker>
          </b-form-group>
        </div>
        <div class="col-lg-6">
          <b-form-group label="End Date *">
            <b-form-datepicker v-model="barrierForm.end_at" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" reset-button today-button></b-form-datepicker>
          </b-form-group>
        </div>
      </div>

      <b-form-group label="Remark *">
        <b-form-textarea v-model.lazy="barrierForm.remark"></b-form-textarea>
      </b-form-group>

    </b-modal>
    <!-- End Barrier Modal -->


    <div style="z-index: -2" id="printMe" v-html="printMe"></div>

  </div>
</template>

<script>
import {checkUserPermissions} from '@/plugins/functions'
import { MonthPickerInput } from 'vue-month-picker'
import {mapState, mapGetters} from "vuex";
import CRUD_API from '@/services/crud'
import moment from 'moment'
import API from "@/services/api";

export default {
  name: "StudentMarker",
  props: [ 'student_id', 'institute_id', 'institutes' ],
  components: { MonthPickerInput },
  computed: {
    ...mapState({ user: state => JSON.parse(state.auth.user) }),
    ...mapGetters({
      freeCardTypes: 'select/free_card_types',
      paymentMethods: 'select/payment_types',
    }),
  },
  watch: {
    // payment_month() {
    //   if (this.payment_month === '') {
    //     this.payment_month = this.$moment.year() + '-' + this.$moment.month() + '-01'
    //   }
    // },
    discount_type() {
      this.updateCart()
    },
    half_card(){
      this.updateCart()
    }
  },
  data() {
    return {
      selected: [],
      tableRecords: [],
      paymentRecords: [],
      tableFields: [
        // { key: 'id', label: 'ID', sortable: false },
        { key: 'details', label: 'Name', sortable: true },
        { key: 'discount_type', label: 'Disc. Type', sortable: false, },
        { key: 'discount_value', label: 'Disc. Value', sortable: false, },
        { key: 'payable', label: 'Payable', sortable: false, },
        { key: 'action', label: 'Action', sortable: false, class: 'text-right text-capitalize' }
      ],

      note: null,
      payment_method: 1,
      default_year: parseInt(moment().format('Y'), 10),
      default_month: parseInt(moment().format('M'), 10),
      payment_month: {
        from: null,
        to: null,
        month: null,
        year: null
      },
      cartSubTotal: 0.00,
      cartDiscTotal: 0.00,
      cartTotal: 0.00,
      processingPayment: false,


      // Delete operation
      deleteModal: {
        id: null,
        class_detail_id: null,
        student_name: "N/A",
        class_name: "N/A",
      },

      // Free Card Data
      freeCardForm: {
        class_detail_id: null,
        free_card_type_id: null,
        start_at: {
          from: null,
          to: null,
          month: null,
          year: null
        },
        end_at: {
          from: null,
          to: null,
          month: null,
          year: null
        },
        remark: '',
        status: 0
      },


      // Add Barrier Operation
      barrierForm: {
        class_detail_id: null,
        start_at: null,
        end_at: null,
        remark: '',
        status: 0
      },

      printNow: false,
      printMe: '',
    }
  },
  created() {
    this.$store.dispatch('select/fetchRecords', { id: 'free-card-types' })
    this.$store.dispatch('select/fetchRecords', { id: 'payment-types' })
  },
  mounted() {
    if (this.student_id) {
      this.fetchData()
    }
  },
  methods: {
    checkUserPermissions,
    setPaymentMonth(date) {
      this.payment_month = date
    },
    updateCartItem(id, t) {
      this.selected.forEach(item => {
        if(item.id === id) {
          item.discount_type = 0 + t
        }
      })
      this.updateCart()
    },
    async fetchData() {
      await CRUD_API.index('/api/backend/pages/classes', {
        params: {
          page: 1,
          per_page: 50,
          institute_id: this.institute_id,
          student_id: this.student_id
        }
      }).then((result) => {
        this.tableRecords = result.data.data.data
      }).catch((error) => {
        console.log(error)
      }).finally(() => {
        this.$refs.marker_table.refresh()
      })
    },
    rowSelected(item){
      this.selected = item
      this.updateCart()
      if(this.payment_month === '') {
        this.payment_month = this.$moment.format('%Y-%m-01')
      }
    },
    updateCart() {
      this.cartSubTotal = 0
      this.cartDiscTotal = 0
      this.cartTotal = 0

      this.selected.forEach((item) => {
        item.payable = item.class_fee

        if(typeof item.half_card !== "undefined") {
          if(item.half_card > 0) {
            item.payable = item.payable / 2
          }
        }


        let disc_amount = 0
        if (typeof item.discount_value === "undefined" || item.discount_value === null) {
          item.discount_value = 0
        }

        if (item.discount_value > 0) {
          if (item.discount_type > 0) {
            disc_amount = (item.discount_value)
          } else {
            disc_amount = ((item.payable * item.discount_value) / 100);
            if(disc_amount < 1)disc_amount = 0
          }
        }

        item.payable = item.payable - disc_amount
      })

      this.selected.forEach(item => {
        this.cartSubTotal += item.class_fee
        this.cartTotal += item.payable
      })
      this.cartDiscTotal = this.cartSubTotal - this.cartTotal
    },
    async submitInvoice() {
      this.processingPayment = true;
      let invoice = null
      await CRUD_API.create('/api/backend/pages/save-payment', {
        student_id: this.student_id,
        institute_id: this.institute_id,
        payment_method: this.payment_method,
        payment_month: this.payment_month,
        subtotal: this.cartSubTotal,
        discounts: this.cartDiscTotal,
        total: this.cartTotal,
        payments: this.selected,//.map((item) => [{ id: item.class_detail_id,  }])
        note: this.note
      }).then((result) => {
        if (result.data.success) {
          invoice = result.data.data.invoice
          this.$toasted.success("Payments saved successfully.")
        } else {
          this.$toasted.error(result.data.message)
        }
      }).catch((error) => {
        console.log(error)
        this.$toasted.error("[SYSTEM ERROR] Payments not saved.")
      }).finally(() => {
        this.processingPayment = false;
      })
      if (invoice && this.printNow) {
        let windowTitle = "Invoice - " + invoice.id + '';
        await API.post('/api/backend/print-invoice', invoice)
            .then(resp => {this.printMe = resp.data.view;})
            .then(()=>this.$htmlToPaper('printMe', { windowTitle }))
            .finally(() => {this.printMe = '';})
      }
    },



    // Free Card Modal
    clearFreeCardModal() {
    },
    showFreeCardModal(index, item, button){
      this.freeCardForm.class_detail_id = item.id
      this.freeCardForm.institute_id = item.institute_id
      this.freeCardForm.student_id = this.student_id
      this.freeCardForm.start_at = {
        from: moment().format('YYYY-MM-01'),
        to: moment().format('YYYY-MM-01'),
        month: parseInt(moment().format('M'), 10),
        year: parseInt(moment().format('Y'), 10),
        monthIndex: parseInt(moment().format('M'), 10)
      }
      this.freeCardForm.end_at = {
        from: moment().format('YYYY-MM-01'),
        to: moment().format('YYYY-MM-01'),
        month: parseInt(moment().format('M'), 10),
        year: parseInt(moment().format('Y'), 10),
        monthIndex: parseInt(moment().format('M'), 10)
      }
      this.$root.$emit('bv::show::modal', 'free_card_modal', button);
    },
    submitFreeCardModal() {
      CRUD_API.create('/api/backend/pages/free-cards', this.freeCardForm)
          .then((response) => {
            if(response.data.success) {
              // this.clearFreeCardModal()
              this.$toasted.success(response.data.message)
            } else {
              this.$toasted.error(response.data.message)
            }
          })
          .catch((errors) => {
            console.log(errors)
            this.$toasted.error("Server encountered an error!")
          })
    },



    // Barrier Modal
    clearBarrierModal() {
    },
    showBarrierModal(index, item, button){
      this.barrierForm.class_detail_id = item.id
      this.barrierForm.institute_id = item.institute_id
      this.barrierForm.student_id = this.student_id
      this.$root.$emit('bv::show::modal', 'barrier_modal', button);
    },
    submitBarrierModal() {
      CRUD_API.create('/api/backend/pages/barriers', this.barrierForm)
          .then((response) => {
            if(response.data.success) {
              // this.clearFreeCardModal()
              this.$toasted.success(response.data.message)
            } else {
              this.$toasted.error(response.data.message)
            }
          })
          .catch((errors) => {
            console.log(errors)
            this.$toasted.error("Server encountered an error!")
          })
    },



    clearDeleteModals() {
      this.deleteModal.id = null
      this.deleteModal.class_detail_id = null
      this.deleteModal.student_name = null
      this.deleteModal.class_name = null
    },
    showDeleteModal(index, item, button) {
      // this.deleteModal.id = item.id
      this.deleteModal.class_detail_id = item.id
      this.deleteModal.class_name = item.name
      this.$root.$emit('bv::show::modal', 'delete_modal', button)
    },
    submitDeleteModal(){
      API.remove_class_from_student({
        class_detail_id: this.deleteModal.class_detail_id,
        student_id: this.student_id,
      })
          .then((resp) => {
            if(resp.data.success) {
              this.$toasted.success(resp.data.message)
            } else {
              this.$toasted.error(resp.data.message)
            }
          })
          .catch((err) => {
            console.log(err)
            this.$toasted.error("Error occurred while removing class from the student")
          })
          .finally(() => {
            this.fetchData()
          })
    },
    togglePrint() {
      this.printNow = !this.printNow
    }
  },
}
</script>

<style scoped>

</style>
